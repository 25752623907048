import React from 'react';
import { gsap } from 'gsap';



class Doodlebug extends React.Component {
  
  componentDidMount(){
    gsap.set("#human-arm", {scale: 0.5, rotation: 150, x:55})
    gsap.set("#human-arm", {opacity: 1})
    gsap.set("#mrow", {opacity: 0})
    
    const tl = gsap.timeline({repeat: -1, repeatDelay: 2});
    const tlPeople = gsap.timeline();
    const tlWords = gsap.timeline();

    
    tlPeople.to("#cat-arm", { rotation: -70, x: 2, duration: 1, ease: "slow (0.7, 0.7, false)" })
      .to("#mrow", {opacity: 0.8, duration: 0.5}, 1.2)
      .to("#human-arm", { x: 43, y: 25, duration: 2, ease: "power8.out"})
      .to("#mrow", {opacity: 0, duration: 1}, 2)
      .to("#cat-arm", { rotation: 0, x: 0, duration: 1, ease: "slow (0.7, 0.7, false)"  })
      .to("#human-arm", { x:55, y: 0, duration: 2, ease: "power4.in"})

    // tlWords.to(".tap", { x: -20, duration: 1, stagger: 0.5})
          tlWords.to("tap", { y: 50, duration: 1, stagger: 0.5})

    tl.add(tlWords)
      .add(tlPeople)

  }

  render () {
    return (

      <div style={{height:"900px", width:"1000px"}}>
    <svg height="100%" viewBox="0 40 40 100">
      <g id="dev-viewbox">
        <path
          d="M 0,0 h 100 v 100 h -100 z"
          // stroke="cadetblue"
          fill="none"
        />
      </g>
      <g id="prod-viewbox" >
        <path
          d="M 0,40 h 40 v 60 h -40 z"
          // stroke="aliceblue"
          fill="none"
        />
      </g>
      <g id="cat">
        <path
          d="M 20,80 c 0,0 0.500999,-2.005 1.378999,-3.008 0.877,-1.002 0.376,-4.136 0,-5.138 -0.376,-1.003 0.25,-2.256 1.128,-2.131 0.877,0.125 1.629,-2.1300003 1.002,-2.3810003 -0.626,-0.25 -1.504,-2.005 -2.381,-2.256 -0.877,-0.251 -1.576999,-1.603 -1.127999,-2.256 0.688999,-1.0030001 -1.378001,-0.2510001 -1.880001,1.504 0,0 -2.883,0.877 -3.008,3.384 -0.1250002,2.5070003 -0.3760002,2.7640003 -2.1310002,3.6380003 -1.754,0.874 -5.8179998,3.264 -6.2669998,6.765 -0.68900002,5.389 2.3809998,8.146 1.0029998,9.399 -1.3789998,1.253 -1.3179998,9.043 9.1490002,6.267 6.141,-1.629 -0.04,-1.629 -1.649,-1.253 -1.6090002,0.376 -7.3750002,0.25 -5.4950002,-3.259 0,0 6.2670002,0 7.2700002,0 1.002,0 0.063,-1.755 -1.775,-1.755 -1.4890002,0 -0.105,-1.754 0.897,-1.504 1.003,0.251 2.381,3.259 3.509001,3.259 1.127999,0 1.252999,0 2.004999,0 0.752,0 0.877,-1.316 -0.25,-1.566 -1.128,-0.251 -2.130999,-0.94 -2.130999,-1.943 0,-1.003 0.523,-5.005 0.753,-5.766 z"
          id="cat-body"
        />
        <path
        fill="black"
        stroke="none"
          d="M 15,81 c 0,0 0.593147,-1.496194 1.187839,-1.988681 1.003656,-0.831165 3.671095,-1.344063 3.671095,-1.344063 0,0 -0.154979,2.260491 0.02006,3.370186 0.261461,1.657581 0.727797,3.320201 1.521069,4.798936 0.283173,0.527861 0.722029,0.958351 1.126934,1.399799 0.300501,0.327622 1.087754,0.496114 0.96291,0.922789 -0.0943,0.322292 -0.652562,0.197494 -0.988358,0.195027 -0.465988,-0.0034 -0.979865,-0.03202 -1.36743,-0.290762 -0.642988,-0.429273 -0.936079,-1.23661 -1.315307,-1.910328 -0.320051,-0.568588 -0.408741,-1.265071 -0.802425,-1.785396 -1.05415,-1.393253 -4.016387,-3.367507 -4.016387,-3.367507 z"
          id="cat-arm"
        />
        {/* <circle id="pivot" fill="orange" cx="17" cy="78" r="1" /> */}
      </g>

      <g id="chair">
        <path
          id="base"
          stroke="none"
          fill="#4d4d4d"
          d="M 33,59 c -0.1,1 -0.1,3 2,4 a 80,90 0 0,0 40,0 c 2.1,-1 2.1,-3 2,-4"
        />
        <path
          id="cushion"
          stroke="none"
          fill="#8f0c0c"
          d="M 33,59 c 3,-8 15,-6 20,-4 q 2,1 4,0 c 5,-2 17,-4 20,4 a 80,90 0 0,1 -44,0 z"
        />
      </g>

        <path
          id="human-arm"
          d="m89,28 c0,0,1.643-2.426,1.409-4.147c-0.235-1.721-1.096-1.252-1.096-1.252  s0.782-1.408,0.939-1.878c0.157-0.469,0.469-1.408-0.469-1.721c-0.939-0.313-2.739,1.33-2.739,1.33s-0.548-0.704-1.487-0.469  c-0.939,0.235-3.365,2.973-3.991,3.756c-0.626,0.783-9.233,6.417-9.233,6.417s3.286-5.008,2.347-5.477  c-0.939-0.47-5.947,3.756-8.451,7.199c-2.504,3.443-1.096,8.451-1.096,8.451S29.68,60.449,29.561,61.768  c-0.12,1.319-19.567,0.376-20.35,0.532l3.311,18.535c35.951-7.401,31.333-10.566,58.447-35.027c0,0,2.269-0.939,5.477-2.661  c3.208-1.721,6.651-4.93,7.199-5.556c0.548-0.626,3.756-3.677,4.226-4.617c0.469-0.939,2.582-2.504,2.426-3.599  C90.14,28.281,89.357,28.203,89.357,28.203z"
          opacity="0"
          fill="#f7ded2"
        />
        <text class="tap1">
          <tspan 
            fontFamily="Chalkboard"
            fontSize="3"
            x="50" y="30"
          >
            TAP
          </tspan>
        </text>
        <text class="tap">
          <tspan 
            fontFamily="Chalkboard"
            fontSize="3"
            x="55" y="25"
          >
            TAP
          </tspan>
        </text>
        <text class="tap">
          <tspan 
            fontFamily="Chalkboard"
            fontSize="3"
            x="62" y="28"
          >
            TAP
          </tspan>
        </text>
        <text id="mrow">
          <tspan 
            fontFamily="Chalkboard"
            fontSize="3"
            x="24" y="64"
          >
            mrow
          </tspan>
        </text>

    </svg>
  </div>
    )
  }
}

export default Doodlebug