import React from 'react';
import {
  BrowserRouter,
  Route,
} from 'react-router-dom';
import './App.css';
import { v4 as uuidv4 } from 'uuid';
import Game from './components/Game'
import Doodlebug from './components/Doodlebug'

const USER_ID_STORAGE_KEY = 'userId';

function App() {
  const getOrCreateUserId = () => {
    let userId = localStorage.getItem(USER_ID_STORAGE_KEY);
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem(USER_ID_STORAGE_KEY, userId);
    }
    return userId;
  }

  const socket = new WebSocket(process.env.REACT_APP_BACKEND_WS);
  const userId = getOrCreateUserId();
  const sendMessage = data => {
    socket.send(JSON.stringify({data, userId}))
  }
  const attachOnMessage = callback => {
    socket.onmessage = callback;
  }

  return (
    <BrowserRouter>
      <div className="App">
        <Route
          exact path="/"
          render={(props) => (
            <Game
              sendMessage={sendMessage}
              attachOnMessage={attachOnMessage}
              gameId={null}
              userId={userId}
            />)}
        >
        </Route>
        <Route
          exact path="/:gameId"
          render={(props) => (
            <Game
              sendMessage={sendMessage}
              attachOnMessage={attachOnMessage}
              gameId={props.match.params.gameId}
              userId={userId}
            />)}
        />
        <Route
          exact path="/doodlebug"
          render={(props) => <Doodlebug/> }
        ></Route>
      </div>
    </BrowserRouter>
  );
}

export default App;
