import React from 'react';
import Square from './Square';

class Board extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      squares: this.props.board.tiles
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.board.tiles !== prevProps.board.tiles) {
      this.setState({squares: this.props.board.tiles})
    }
  }

  findSpace(rowIndex, colIndex) {
    const squares = this.state.squares.slice();
    const toCheck = [
      [rowIndex, colIndex - 1],
      [rowIndex, colIndex + 1],
      [rowIndex - 1, colIndex],
      [rowIndex + 1, colIndex],
    ];

    for (const coords of toCheck) {
      const rowIndex = coords[0];
      const colIndex = coords[1];
      if (rowIndex < 0 || colIndex < 0) {
        continue;
      }
      if (rowIndex >= squares.length || colIndex >= squares[rowIndex].length) {
        continue;
      }
      if (squares[rowIndex][colIndex] === null) {
        return [rowIndex, colIndex];
      }
    }

    return null
  }

  swapSquares(coordsA, coordsB) {
    const squares = this.state.squares.slice();
    const valueA = squares[coordsA[0]][coordsA[1]]
    const valueB = squares[coordsB[0]][coordsB[1]]
    squares[coordsA[0]][coordsA[1]] = valueB
    squares[coordsB[0]][coordsB[1]] = valueA
    this.setState({squares: squares})
  }

  handleSquareClick(rowIndex, colIndex) {
    const coordsBlank = this.findSpace(rowIndex, colIndex);
    if (coordsBlank === null) return;
    const coordsClick = [rowIndex, colIndex]
    this.swapSquares(coordsClick, coordsBlank)
    this.props.validateBoard(this.state.squares)
  }

  renderSquare(content, rowIndex, colIndex) {
    return <Square
        value={content}
        key={content}
        rowindex={rowIndex}
        colindex={colIndex}
        onClick={() => this.handleSquareClick(rowIndex, colIndex)}
      />
  }

  renderBoardRow(row, rowIndex) {
    return (
      <div
        className="board-row"
        rowindex={rowIndex}
        key={rowIndex} >
        {row.map((squareContent, colIndex) => this.renderSquare(squareContent, rowIndex, colIndex))}
      </div>
    )
  }

  renderBoard(rows) {
    return rows.map((row, rowIndex) => this.renderBoardRow(row, rowIndex));
  }

  render () {
    return (
      <div className="board">
        {this.renderBoard(this.state.squares)}
      </div>
    );
  }
}

export default Board
