import React from 'react';
import { Redirect } from 'react-router-dom';
import Board from './Board';

const axios = require('axios');

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.validateBoard = this.validateBoard.bind(this);
    this.state = {
      board: null,
      newGameId: null,
    }
  }

  async getNewGame() {
    const result = await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/new-game`);
    return result.data.gameId;
  }

  async getBoard(gameId, userId) {
    if (gameId === null) {
      const newGameId = await this.getNewGame();
      this.setState({newGameId});
    } else {
      const result = await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/board/${gameId}/${userId}`)
      this.setState({board: result.data});
    }
  }

  componentDidMount() {
    this.props.attachOnMessage(event => {
      if (event.data !== 'false') {
        this.setState({board: JSON.parse(event.data)})
      }
    });
    this.getBoard(this.props.gameId, this.props.userId);
  }

  validateBoard(currentSquares) {
    this.props.sendMessage({
      board: this.state.board,
      gameId: this.props.gameId || this.state.gameId
    })
  }

  render (){
    if (this.state.newGameId !== null) {
      return <Redirect to={`/${this.state.newGameId}`}/>
    }

    if (this.state.board === null) {
      return null;
    }

    return (
      <div className="game">
        <h1>{this.state.board.category.toUpperCase()}</h1>
          <Board
            board={this.state.board}
            validateBoard={this.validateBoard}
          />
        <div className="controls">
          <a href="/" className="button">New</a>
        </div>
      </div>
    );
  }
}

export default Game
