import React from 'react';
const basePath = process.env.REACT_APP_BACKEND_URL

class Square extends React.Component {

  render () {
    return (
      <div className="tile-container">
        <img
          className="tile"
          onClick={() => this.props.onClick()}
          src={`${basePath}/${this.props.value || 'blank.png'}`}
          alt="tile"
        />
      </div>
    );
  }
}

export default Square 
